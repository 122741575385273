import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Nav from '../components/Nav'

import SplashTitle from '../components/SplashTitle'
import styles from './index.module.scss'
import SEO from '../components/seo'
import Footer from '../components/Footer'

const IndexPage = ({ data }) => {
  const { Site_Name, Homepage_Image } = data.strapiRandom
  const img = Homepage_Image.childImageSharp.fluid

  return (
    <div className={styles.indexwrapper}>
      <SEO />
      <div className={styles.splashcontainer}>
        <SplashTitle title={Site_Name} subtitle="Fine Art" />
        <Nav />
        <Img
          className={styles.painting}
          imgStyle={{ objectFit: 'contain' }}
          fluid={img}
        />
      </div>
      <Footer />
    </div>
  )
}

export default IndexPage

export const query = graphql`
  query {
    strapiRandom {
      id
      Site_Name
      Homepage_Image {
        id
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
            presentationWidth
            presentationHeight
          }
        }
      }
    }
  }
`
