import React from 'react'
import styles from './splashtitle.module.scss'

const SplashTitle = props => (
  <h1 className={styles.title}>
    {props.title}
    <span className={styles.subtitle}> {props.subtitle}</span>
  </h1>
)

export default SplashTitle
